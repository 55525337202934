import { useEffect, useState } from "react";
import "./Predictor.css";
import { useLocation, useNavigate } from "react-router-dom";
import Dropzone from "../../components/dropzone/Dropzone";
import { useDispatch } from "react-redux";
import { setLoadingFalse, setLoadingTrue } from "../../slices/loadingSlice";
import { ModelType } from "../modelSelect/ModelSelect";

export enum ModelInputType {
  Manual = "manual",
  File = "file",
}
const Predictor = () => {
  const location = useLocation();
  const { state } = location as { state: { modelType: string } };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [amount, setAmount] = useState<number | string>("");
  const [oldbalanceOrig, setOldbalanceOrig] = useState<number | string>("");
  const [newbalanceOrig, setNewbalanceOrig] = useState<number | string>("");
  const [oldbalanceDest, setOldbalanceDest] = useState<number | string>("");
  const [newbalanceDest, setNewbalanceDest] = useState<number | string>("");
  const [type, setType] = useState<string>("CASH-IN");
  const [modelName, setModelName] = useState<string>("");
  const [fraudDetected, setFraudDetected] = useState<boolean | null>(null);
  const [inputType, setInputType] = useState<string>(ModelInputType.Manual);
  const [file, setFile] = useState<File | null>(null);
  const [resultFile, setResultFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    if (!state) {
      navigate("/modelselect");
    } else {
      if (state.modelType === ModelType.LR) {
        setModelName("Logistic Regression");
      } else if (state.modelType === ModelType.RFC) {
        setModelName("Random Forest Classifier");
      } else if (state.modelType === ModelType.GB) {
        setModelName("Gradient Boosting");
      } else if (state.modelType === ModelType.CNN) {
        setModelName("Feed Forward Neural Network");
      } else if (state.modelType === ModelType.KAN) {
        setModelName("Komogorov Arnolds Network");
      } else {
        navigate("/modelselect");
      }
    }
  }, []);
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    dispatch(setLoadingTrue());
    // Prepare the data to be sent to the server to /predict1
    const data = {
      amount: amount,
      oldbalanceOrg: oldbalanceOrig,
      newbalanceOrig: newbalanceOrig,
      oldbalanceDest: oldbalanceDest,
      newbalanceDest: newbalanceDest,
      type: type,
    };
    fetch("https://api.risk-radar.online/predict1", {
      // fetch("http://localhost:5000/predict1", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(setLoadingFalse()); // Hide the loader after completion
        if (data.error) {
          // Display error message
          setFraudDetected(null);
          setError(data.error);
        } else {
          // Set the result
          if (data.fraud == 1) {
            setFraudDetected(true);
          } else {
            setFraudDetected(false);
          }
          setError(null);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setError("An unexpected error occurred. Please try again later.");
      });
  };
  const handleFileSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(setLoadingTrue());
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
      formData.append("model_type", state.modelType);
    }
    fetch("https://api.risk-radar.online/uplod", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          dispatch(setLoadingFalse()); // Hide the loader after completion
          // Display error message
          setError(data.error);
          setFraudDetected(null);
        } else {
          dispatch(setLoadingFalse()); // Hide the loader after completion
          // Show success icon and update download
          setResultFile(data.file);
        }
        dispatch(setLoadingFalse());
      })
      .catch((error) => {
        console.error("Error:", error);
        setError("An unexpected error occurred. Please try again later.");
      });
  };

  const handleFileUpload = (file: File) => {
    console.log("Uploaded file:", file);
    setFile(file);
  };
  return (
    <div className="Predictor">
      <div className="Predictor-card">
        <h1 style={{ color: "white" }}>{`Model Type: ${modelName}`}</h1>
        <div className="Predictor-tabs">
          <div
            className={`Predictor-tab ${
              inputType === ModelInputType.Manual ? "active" : ""
            }`}
            onClick={() => {
              if (inputType !== ModelInputType.Manual) {
                setInputType(ModelInputType.Manual);
                setError(null);
              }
            }}
          >
            Manual Input
          </div>
          <div
            className={`Predictor-tab ${
              inputType === ModelInputType.File ? "active" : ""
            }`}
            onClick={() => {
              if (inputType !== ModelInputType.File) {
                setInputType(ModelInputType.File);
                setError(null);
              }
            }}
          >
            File Upload
          </div>
        </div>
        {inputType === ModelInputType.Manual && (
          <div className="model-component">
            <form onSubmit={handleSubmit}>
              {/* Type Dropdown */}
              <div className="form-group">
                <label htmlFor="type">Type:</label>
                <select
                  id="type"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  <option value="CASH-IN">CASH-IN</option>
                  <option value="CASH-OUT">CASH-OUT</option>
                  <option value="DEBIT">DEBIT</option>
                  <option value="PAYMENT">PAYMENT</option>
                  <option value="TRANSFER">TRANSFER</option>
                </select>
              </div>
              {/* Amount Field */}
              <div className="form-group">
                <label htmlFor="amount">Amount:</label>
                <input
                  type="number"
                  id="amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder="Enter amount"
                />
              </div>

              {/* Old Balance Orig Field */}
              <div className="form-group">
                <label htmlFor="oldbalanceOrig">Old Balance (Orig):</label>
                <input
                  type="number"
                  id="oldbalanceOrig"
                  value={oldbalanceOrig}
                  onChange={(e) => setOldbalanceOrig(e.target.value)}
                  placeholder="Enter old balance (orig)"
                  required
                />
              </div>

              {/* New Balance Orig Field */}
              <div className="form-group">
                <label htmlFor="newbalanceOrig">New Balance (Orig):</label>
                <input
                  type="number"
                  id="newbalanceOrig"
                  value={newbalanceOrig}
                  onChange={(e) => setNewbalanceOrig(e.target.value)}
                  placeholder="Enter new balance (orig)"
                  required
                />
              </div>

              {/* Old Balance Dest Field */}
              <div className="form-group">
                <label htmlFor="oldbalanceDest">Old Balance (Dest):</label>
                <input
                  type="number"
                  id="oldbalanceDest"
                  value={oldbalanceDest}
                  onChange={(e) => setOldbalanceDest(e.target.value)}
                  placeholder="Enter old balance (dest)"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="newbalanceDest">New Balance (Dest):</label>
                <input
                  type="number"
                  id="newbalanceDest"
                  value={newbalanceDest}
                  onChange={(e) => setNewbalanceDest(e.target.value)}
                  placeholder="Enter new balance (Dest)"
                  required
                />
              </div>
              {error && (
                <p style={{ color: "red", fontSize: "16px" }}>*{error}</p>
              )}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button className="Predictor-btn-submit" type="submit">
                  Analyze
                </button>
              </div>
            </form>
            {fraudDetected != null && (
              <div className="Predictor-result">
                <h2
                  style={fraudDetected ? { color: "red" } : { color: "white" }}
                >
                  {fraudDetected ? "Fraud Detected !!!" : "No Fraud Detected"}
                </h2>
              </div>
            )}
          </div>
        )}
        {inputType == ModelInputType.File && (
          <div className="model-file-component">
            <div className="Predictor-dropzone">
              <Dropzone onFileUpload={handleFileUpload} />
              {file && (
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "20px",
                    color: "white",
                  }}
                >
                  {file.name}
                </p>
              )}
              {error && (
                <p style={{ color: "red", fontSize: "16px" }}>*{error}</p>
              )}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  className="Predictor-btn-submit"
                  onClick={(e) => {
                    handleFileSubmit(e);
                  }}
                >
                  Analyze
                </button>
              </div>
            </div>
            {resultFile && (
              <div className="Predictor-file-download">Download Result</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Predictor;
