import { configureStore } from '@reduxjs/toolkit';
import UserSliceReducer from '../../slices/userSlice'
import LoadingSliceReducer from '../../slices/loadingSlice'
import TabSliceReducer from '../../slices/tabSlice'

export const store = configureStore({
    reducer: {
        user: UserSliceReducer,
        loading: LoadingSliceReducer,
        tabs: TabSliceReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
