import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import Profile from "../models/Profile";

export interface UserSliceState {
  user: Profile | null;
}

const initialState: UserSliceState = {
  user: null,
};

export const UserSlice = createSlice({
  name: "loadingSlice",
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload as Profile;
    },
    resetUser: (state) => {
      state.user = null;
    },
  },
});
//example for when receiving a payload
// setUserList: (state, action: PayloadAction<List>) => {
//     const lists: List[] = state.lists;
//     if (!lists.some((list) => list.id === action.payload.id)) {
//       lists.push(action.payload);
//       state.lists = lists;
//     }
//   },

export const { setUser, resetUser } = UserSlice.actions;
export default UserSlice.reducer;
