import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface loadingSliceState {
  loading: boolean;
}

const initialState: loadingSliceState = {
  loading: false,
};

export const loadingSlice = createSlice({
  name: "loadingSlice",
  initialState: initialState,
  reducers: {
    setLoadingTrue: (state) => {
      state.loading = true;
    },
    setLoadingFalse: (state) => {
      state.loading = false;
    },
  },
});

export const { setLoadingTrue, setLoadingFalse } = loadingSlice.actions;
export default loadingSlice.reducer;
