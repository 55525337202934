import ApiResponse from "../models/ApiResponse";
import { supabase } from "../utils/supabase/Supabase";

interface params {
  email: string;
  password: string;
}
async function emailLogin({
  email,
  password,
}: params): Promise<ApiResponse<boolean>> {
  try {
    const { error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });
    if (error) {
      throw new Error(error.message);
    }
    return { data: true };
  } catch (e) {
    const err = e instanceof Error ? e.message : "error";
    return { error: err };
  }
}

async function emailSignUp({
  email,
  password,
}: params): Promise<ApiResponse<boolean>> {
  try {
    const { error } = await supabase.auth.signUp({
      email: email,
      password: password,
    });
    if (error) {
      throw new Error(error.message);
    }
    return { data: true };
  } catch (e) {
    const err = e instanceof Error ? e.message : "error";
    return { error: err };
  }
}

export enum oAuth {
  LINKEDIN = "linkedin_oidc",
  GOOGLE = "google",
}
interface oAuthLoginParams {
  authType: oAuth;
}
async function oAuthLogin({
  authType,
}: oAuthLoginParams): Promise<ApiResponse<boolean>> {
  try {
    const { data: login, error } = await supabase.auth.signInWithOAuth({
      provider: authType,
    });
    if (error) {
      throw new Error(error.message);
    }

    return { data: true };
  } catch (e) {
    const err = e instanceof Error ? e.message : "error";
    return { error: err };
  }
}

async function logout(): Promise<ApiResponse<boolean>> {
  try {
    const session = await supabase.auth.getSession();
    if (session.data.session == null) {
      throw new Error("User not logged in.");
    }
    const { error } = await supabase.auth.signOut();
    if (error) {
      throw new Error(error.message);
    }
    return { data: true };
  } catch (e) {
    const err = e instanceof Error ? e.message : "error";
    return { error: err };
  }
}

export { emailLogin, emailSignUp, logout, oAuthLogin };
