import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import "./Dropzone.css";

interface DropzoneProps {
  onFileUpload: (file: File) => void; // Callback function for file upload
  accept?: { [key: string]: string[] }; // Accepted file types
}

const Dropzone: React.FC<DropzoneProps> = ({
  onFileUpload,
  accept = {
    "text/csv": [".csv"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
      ".xlsx",
    ],
  }, // Default to accept .csv and .xlsx files
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        onFileUpload(acceptedFiles[0]);
      }
    },
    [onFileUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    multiple: false, // Allow only one file
  });

  return (
    <div
      {...getRootProps()}
      className={`dropzone ${isDragActive ? "active" : ""}`}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop your file here...</p>
      ) : (
        <p>
          Drag & drop your file here, or <span>click to upload</span>.
        </p>
      )}
    </div>
  );
};

export default Dropzone;
