import { useState } from "react";
import "./Login.css";
import { emailLogin } from "../../api/AuthApi";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import { ReactComponent as EmailIco } from "../../assets/svg/email.svg";
import { ReactComponent as PasswdIco } from "../../assets/svg/lock.svg";
import { ReactComponent as EyeClosed } from "../../assets/svg/passwd-hidden.svg";
import { ReactComponent as EyeOpen } from "../../assets/svg/passwd-show.svg";
import { getUserProfile } from "../../api/ProfileApi";
import { useDispatch } from "react-redux";
import { setUser } from "../../slices/userSlice";
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const getProfile = async () => {
    const user = await getUserProfile();
    if (!user.error && user.data) {
      dispatch(setUser(user.data));
    }
  };
  const handleEmailLogin = async () => {
    setLoading(true);
    if (!email || !password) {
      setError("Email or Password is missing");
    } else {
      const response = await emailLogin({ email: email, password: password });
      if (response.error) {
        console.log(response.error);
        setError(response.error);
      } else if (response.data) {
        setError("");
        await getProfile();
        setLoading(false);
        navigate("modelselect", { replace: true });
      }
    }
    setLoading(false);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  return (
    <div className="Login">
      <div className="Login-card">
        <h1 style={{ color: "white" }}>Login</h1>
        <div className="Login-fields">
          <div
            className="Login-error"
            style={{ visibility: error ? "visible" : "hidden", color: "red" }}
          >
            <p>*{error}</p>
          </div>
          <div className="Login-email">
            <EmailIco className="Login-emailico" />
            <input
              type="text"
              id="username"
              placeholder="Email"
              onChange={handleEmailChange}
              required
            />
          </div>
          <div className="Login-passwd">
            <PasswdIco className="Login-passwdico" />
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              placeholder="Password"
              onChange={handlePasswordChange}
              required
            />
            {showPassword && (
              <EyeOpen
                className="Login-eye"
                onClick={() => setShowPassword(false)}
              />
            )}
            {!showPassword && (
              <EyeClosed
                className="Login-eye"
                onClick={() => setShowPassword(true)}
              />
            )}
          </div>
          <div className="Login-button">
            <button
              className="Login-btn"
              onClick={(e) => {
                if (loading) return;
                e.preventDefault();
                e.stopPropagation();
                handleEmailLogin();
              }}
            >
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "2.5rem",
                  }}
                >
                  <Loader css={{ transform: "scale(0.5)", color: "#fff" }} />
                </div>
              ) : (
                "Sign in"
              )}
            </button>
          </div>
          <p className="Login-card-l-trouble">Having Trouble Signing in?</p>
        </div>
      </div>
    </div>
  );
};

export default Login;
