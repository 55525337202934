import { Route, Routes } from "react-router-dom";
import "./App.css";
import Loader from "./components/loader/Loader";
import Navbar from "./components/navbar/Navbar";
import { useAppSelector } from "./utils/redux/hooks";
import { AuthProvider } from "./utils/RouteValidations/AuthContext";
import PrivateRoutes from "./utils/RouteValidations/PrivateRoutes";
import Login from "./pages/login/Login";
import ModelSelect from "./pages/modelSelect/ModelSelect";
import Account from "./pages/account/Account";
import Predictor from "./pages/predictor/Predictor";
const App = () => {
  const isLoading = useAppSelector((state) => state.loading.loading);
  return (
    <div className="App">
      {isLoading && (
        <div
          className="App-pageLoading"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Loader css={{ transform: "scale(2)" }} />
        </div>
      )}
      <Navbar />
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route element={<PrivateRoutes />}>
            <Route path="/modelselect" element={<ModelSelect />}></Route>
            <Route path="/account" element={<Account />}></Route>
            <Route path="/predict" element={<Predictor />}></Route>
          </Route>
        </Routes>
      </AuthProvider>
    </div>
  );
};

export default App;
