import { ModelCardData } from "../../pages/modelSelect/ModelSelect";
import "./ModelCard.css";
type Props = {
  data: ModelCardData;
};
const ModelCard = (props: Props) => {
  return (
    <div className="ModelCard">
      <div>
        <div className="ModelCard-heading">{props.data.heading}</div>
        <div className="ModelCard-content">{props.data.content}</div>
      </div>
    </div>
  );
};

export default ModelCard;
