import { createSlice } from "@reduxjs/toolkit";

export interface TabSliceState {
  tab: string;
}
export enum TabTypes {
  ModelSelect = "modelselect",
  Account = "account",
}
const initialState: TabSliceState = {
  tab: TabTypes.ModelSelect,
};

export const TabSlice = createSlice({
  name: "loadingSlice",
  initialState: initialState,
  reducers: {
    setTab: (state, action) => {
      state.tab = action.payload as string;
    },
  },
});

export const { setTab: setTab } = TabSlice.actions;
export default TabSlice.reducer;
