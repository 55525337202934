import "./Navbar.css";
import userAvatar from "../../assets/avatars/termius.jpg";
import { ReactComponent as LogoutIco } from "../../assets/svg/logout.svg";
import { useAppDispatch, useAppSelector } from "../../utils/redux/hooks";
import { useState } from "react";
import { logout } from "../../api/AuthApi";
import { setLoadingFalse, setLoadingTrue } from "../../slices/loadingSlice";
import { resetUser } from "../../slices/userSlice";
import { setTab, TabTypes } from "../../slices/tabSlice";
import { useNavigate } from "react-router-dom";
const Navbar = () => {
  const user = useAppSelector((state) => state.user.user);
  const [isClicked, setIsClicked] = useState(false);
  const dispatch = useAppDispatch();
  const handleLogoutClick = async () => {
    setIsClicked(false);
    dispatch(setLoadingTrue());
    const data = await logout();
    if (data.error) {
      console.log(data.error);
    }
    dispatch(resetUser());
    dispatch(setLoadingFalse());
  };
  const navigate = useNavigate();
  const tab = useAppSelector((state) => state.tabs.tab);
  return (
    <div className="Navbar">
      <h1>RiskRadar</h1>

      {user && (
        <>
          <div className="Navbar-tabs">
            <div
              className={`Navbar-tab ${
                tab == TabTypes.ModelSelect ? "active" : ""
              }`}
              onClick={() => {
                if (tab != TabTypes.ModelSelect) {
                  navigate("/modelselect");
                  dispatch(setTab(TabTypes.ModelSelect));
                }
              }}
            >
              Models
            </div>
            <div
              className={`Navbar-tab ${
                tab == TabTypes.Account ? "active" : ""
              }`}
              onClick={() => {
                if (tab != TabTypes.Account) {
                  navigate("/account");
                  dispatch(setTab(TabTypes.Account));
                }
              }}
            >
              Account
            </div>
          </div>
          <div
            className="Navbar-userIco"
            onClick={() => setIsClicked(!isClicked)}
          >
            <img className="userIco" src={userAvatar} alt="SS" />
            <div className={`Navbar-logout ${isClicked ? "visible" : ""}`}>
              <LogoutIco
                className="Navbar-logout-ico"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleLogoutClick();
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Navbar;
