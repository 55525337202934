import "./Account.css";
import img from "../../assets/avatars/termius.jpg";
import { useAppSelector } from "../../utils/redux/hooks";
const Account = () => {
  const user = useAppSelector((state) => state.user.user);
  return (
    <div className="Account">
      <h2
      //   style={{ textDecoration: "underline" }}
      >
        Account Details
      </h2>
      <div className="Account-details">
        <div className="Account-pic">
          <img src={img} alt="" />
        </div>
        <div className="Account-detail">
          <div className="Account-detail-deit">
            <span className="ac-deit-c1">Name:</span>{" "}
            <span className="ac-deit-c2">{user?.name}</span>
          </div>
          <div className="Account-detail-deit">
            <span className="ac-deit-c1">Username:</span>{" "}
            <span className="ac-deit-c2">{user?.userName}</span>
          </div>
          <div className="Account-detail-deit">
            <span className="ac-deit-c1">Email:</span>{" "}
            <span className="ac-deit-c2">{user?.email}</span>
          </div>
          <div className="Account-detail-deit">
            <span className="ac-deit-c1">Phone:</span>{" "}
            <span className="ac-deit-c2">{user?.phone}</span>
          </div>
          <div className="Account-detail-deit">
            <span className="ac-deit-c1">Role:</span>{" "}
            <span className="ac-deit-c2">{user?.role}</span>
          </div>
          <div className="Account-detail-deit">
            <span className="ac-deit-c1">Plan</span>{" "}
            <span className="ac-deit-c2">Radar Pro</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
