import "./Loader.css";

type Props = {
  css?: React.CSSProperties;
};
const Loader = (props: Props) => {
  return (
    <div className="Loader-wrapper" style={props.css}>
      <div className="Loader-circle"></div>
      <div className="Loader-circle"></div>
      <div className="Loader-circle"></div>
      <div className="Loader-shadow"></div>
      <div className="Loader-shadow"></div>
      <div className="Loader-shadow"></div>
    </div>
  );
};

export default Loader;
