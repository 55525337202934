import { useNavigate } from "react-router-dom";
import ModelCard from "../../components/modelCard/ModelCard";
import "./ModelSelect.css";

export interface ModelCardData {
  modelType: string;
  heading: string;
  content: string;
}

export enum ModelType {
  LR = "linear-reg",
  RFC = "random-fr",
  GB = "gradient-bst",
  KAN = "kan-fed",
  CNN = "cnn",
}

const modelData: ModelCardData[] = [
  {
    modelType: ModelType.LR,
    heading: "Logistic Regression",
    content:
      "A simple and interpretable linear model that predicts the probability of fraudulent transactions based on feature weights derived from data patterns.",
  },
  {
    modelType: ModelType.RFC,
    heading: "Random Forest Classifier",
    content:
      "A robust ensemble model that uses multiple decision trees to classify transactions, reducing overfitting and improving accuracy on diverse datasets.",
  },
  {
    modelType: ModelType.GB,
    heading: "Gradient Boosting",
    content:
      "A powerful boosting algorithm that sequentially improves weak classifiers to accurately distinguish between fraudulent and legitimate transactions.",
  },
  {
    modelType: "cnn",
    // modelType: ModelType.CNN,
    heading: "Feed Forward Neural Network",
    content:
      "A deep learning model with multiple layers that captures complex non-linear relationships in transaction data for effective fraud detection.",
  },
  {
    modelType: ModelType.KAN,
    heading: "Komogorov Arnolds Network",
    content:
      "A specialized neural network architecture inspired by mathematical theorems to model high-dimensional fraud detection problems efficiently.",
  },
];
const ModelSelect = () => {
  const navigate = useNavigate();
  const handleCardClick = (modelType: string) => {
    navigate("/predict", { state: { modelType } });
  };
  return (
    <div className="ModelSelect">
      {modelData.map((model, i) => (
        <div
          key={i}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleCardClick(model.modelType);
          }}
        >
          <ModelCard data={model} />
        </div>
      ))}
    </div>
  );
};

export default ModelSelect;
